<template>
  <div class="pt-5">
    <b-alert show="" variant="light"
      >{{ $t("ACCOUNT.ALERT")
      }}<strong>{{ $t("EMPLOYEES.DETAILS.SUBMIT") }}</strong></b-alert
    >
    <b-form class="pt-4 px-2" v-on:submit.prevent="onSubmit">
      <b-form-group
        :label="this.$t('ACCOUNT.PASS1')"
        label-class="font-weight-bolder"
        label-cols-sm="4"
        label-cols-lg="3"
      >
        <b-form-input
          id="old_password"
          type="password"
          v-model="$v.form.old_password.$model"
          :state="validateState('old_password')"
          :placeholder="$t('ACCOUNT.ENTERPASS1')"
        ></b-form-input>

        <b-form-invalid-feedback v-if="!$v.form.old_password.required">{{
          $t("ALERT.ALERT")
        }}</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        :label="this.$t('ACCOUNT.PASS3')"
        label-class="font-weight-bolder"
        label-cols-sm="4"
        label-cols-lg="3"
      >
        <b-form-input
          id="password"
          type="password"
          v-model="$v.form.password.$model"
          :state="validateState('password')"
          :placeholder="$t('ACCOUNT.ENTERPASS2')"
        ></b-form-input>

        <b-form-invalid-feedback v-if="!$v.form.password.required">{{
          $t("ALERT.ALERT")
        }}</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        :label="this.$t('ACCOUNT.PASS2')"
        label-class="font-weight-bolder"
        label-cols-sm="4"
        label-cols-lg="3"
      >
        <b-form-input
          id="confirm_password"
          type="password"
          v-model="$v.form.confirm_password.$model"
          :state="validateState('confirm_password')"
          :placeholder="$t('ACCOUNT.ENTERPASS3')"
        ></b-form-input>

        <b-form-invalid-feedback v-if="!$v.form.confirm_password.required">{{
          $t("ALERT.ALERT")
        }}</b-form-invalid-feedback>
      </b-form-group>

      <div class="d-flex justify-content-end pt-3">
        <b-button
          variant="primary"
          type="submit"
          v-bind:class="{ 'spinner spinner-right': isBusy }"
        >
          {{ $t("EMPLOYEES.DETAILS.SUBMIT") }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import swal from "sweetalert";

export default {
  name: "EmployeePersonalDetailForm",
  mixins: [validationMixin],
  data: function () {
    return {
      form: {
        old_password: "",
        password: "",
        confirm_password: "",
      },
      isBusy: false,
    };
  },
  validations: {
    form: {
      old_password: {
        required,
      },
      password: {
        required,
      },
      confirm_password: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      console.log("Submit");
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.isBusy = true;
      this.$store
        .dispatch("changeExistingPassword", this.form)
        .then((data) => {
          this.form.id = data.id;
          swal({
            title: this.$t("ALERT.SUCCESS"),
            //text: "Details Has Been Saved",
            icon: "success",
          });
        })
        .finally(() => (this.isBusy = false));
    },
  },
  computed: {},
};
</script>

<style scoped></style>
