<template>
  <AppPageCard>
    <template #card-title>
      {{ $t("PERSONAL.ACCOUNTDETAILS") }}
    </template>

    <template #card-toolbar>
      <b-tabs
        pills
        nav-class="nav-light-primary font-weight-bolder"
        v-model="tabIndex"
      >
        <!-- begin:: Employee General Details tab -->
        <!--        <b-tab :title="$t('EMPLOYEESGENERAL.MESSAGE.EMAIL')" lazy> </b-tab>-->

        <!-- begin:: Employee General Details tab -->
        <b-tab :title="$t('LOGIN.PASSWORD')" lazy> </b-tab>
      </b-tabs>
    </template>

    <!--    <div v-if="tabIndex === 0" class="flex-column">-->
    <!--      <PersonalAccountEmail></PersonalAccountEmail>-->
    <!--    </div>-->

    <div class="d-flex flex-column" v-if="tabIndex === 0">
      <PersonalAccountPassword></PersonalAccountPassword>
    </div>
  </AppPageCard>
</template>

<script>
import PersonalAccountPassword from "@/modules/personal/components/account/PersonalAccountPassword";
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";

export default {
  name: "PersonalAccountPage",
  components: { AppPageCard, PersonalAccountPassword },
  data() {
    return {
      tabIndex: 0,
    };
  },
};
</script>

<style scoped></style>
